<template>
  <div class="addServe">
<!--    <el-drawer
      title="发布"
      :with-header="false"
      show-close
      size="50%"
      append-to-body
      :visibleA.sync="drawer"
      direction="rtl"
    >-->
    <div class="add-heda" >
      <div class="add-heda-text">发布服务</div>
    </div>
        <div class="demand_content" style="width: 80%;margin:20px;">
          <a-form-model ref="form" :model="form" :rules="rules" label-width="auto"  :label-col="{ span:7 }"  :wrapper-col="{ span: 17}">
            <a-form-model-item label="服务名称：" prop="serviceName">
              <a-input v-model="form.serviceName" max-length="32" placeholder="请输入服务名称"/>
            </a-form-model-item>
            <a-form-model-item label="服务类型：" prop="dictId">
              <a-select v-model="form.dictId" placeholder="请选择服务类型" style="width: 100%">
                <a-select-option
                  v-for="item in viceType"
                  :key="item.id"
                  :value="item.id"
                >{{item.name}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="服务联系人：" prop="serviceLinkman">
              <a-input v-model="form.serviceLinkman" max-length="32" placeholder="请输入服务联系人"/>
            </a-form-model-item>
            <a-form-model-item label="联系方式：" prop="phone">
              <a-input v-model="form.phone" max-length="11" placeholder="请输入联系方式"/>
            </a-form-model-item>
            <a-form-model-item label="服务简介：" prop="serviceIntroduction">
              <a-input v-model="form.serviceIntroduction" max-length="255" placeholder="请输入服务简介"/>
            </a-form-model-item>

            <a-form-model-item label="收费方式:" style="margin-bottom: 0px;padding-bottom: 0px;" required>
              <a-col :span="5">
                <a-form-model-item prop="chargeType">
                  <a-select v-model="form.chargeType" placeholder="请选择收费方式" class="chargeTypeSelect" style="width: 100%">
                    <a-select-option
                        v-for="item in chargeList"
                        :key="item.value"
                        :value="item.value"
                    >{{item.lable}}</a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="17" v-if="form.chargeType!==1" >
                <a-form-model-item prop="charge">
                  <a-input style="width: 395px;margin-left: 20px" v-model="form.charge" max-length="11" type="number" placeholder="请填写金额"></a-input>
                  <span>&nbsp;元</span>
                </a-form-model-item>
              </a-col>
            </a-form-model-item>
            <a-form-model-item label="服务周期：" prop="serviceCycle">
              <a-input v-model="form.serviceCycle" max-length="255" placeholder="请输入服务周期"/>
            </a-form-model-item>
            <a-form-model-item label="服务流程："  prop="serviceProcess">
              <a-input v-model="form.serviceProcess" max-length="255" placeholder="请输入服务流程"/>
            </a-form-model-item>
            <a-form-model-item label="服务内容：" prop="serviceContext">
<!--              <div @mouseover="asdasd()">-->
              <Tinymce @asdasd="asdasd()"   ref="Tinymce" v-model="form.serviceContext" width="100%"></Tinymce>
<!--              </div>-->
            </a-form-model-item>
            <a-form-model-item label="发布单位：" prop="releaseUnit">
              <a-input v-model="form.releaseUnit" max-length="100" placeholder="请输入发布单位"/>
            </a-form-model-item>
            <a-form-model-item label="发布人：" prop="publisher">
              <a-input v-model="form.publisher" max-length="50" placeholder="请输入发布人"/>
            </a-form-model-item>
            <a-form-model-item label="图片：" style="margin-bottom: 0px;" prop="img">
              <Uploader @setFileList="setFileList" :limit="1" field="img" :img-str="form.img"
              ></Uploader>
            </a-form-model-item>
            <a-form-model-item label="是否发布：" prop="type">
              <div>
                <a-radio-group v-model="form.type">
                  <a-radio  :value="Number(1)">是</a-radio>
                  <a-radio  :value="Number(0)">否</a-radio>
                </a-radio-group>
              </div>
            </a-form-model-item>
          </a-form-model>
        </div>
        <div style="text-align: center">
          <a-button @click="goDemand()" style="margin-right: 20px">返回服务页面</a-button>
          <a-button @click="onSubmit" v-show="titel==='发布'" type="primary" >发布</a-button>
<!--          <a-button @click="onSubmitUpd" v-show="titel==='修改'" type="primary">修改</a-button>-->
          <a-modal v-model="visibleA" title="服务发布" @ok="handleOk">
            <p>是否确认发布</p>
            <p></p>
            <p></p>
          </a-modal>
        </div>
<!--    </el-drawer>-->
  </div>
</template>

<script>
import Tinymce from '../../components/Tinymce'
import Uploader from '../../components/Uploader'
import {getDictType} from "../../api/company/dictType";
import {addVice} from '../../api/company/vice'
/*const Form = {
  id: undefined,
  serviceName: undefined,
  dictId: undefined,
  serviceLinkman: undefined,
  phone: undefined,
  serviceIntroduction: undefined,
  chargeType: undefined,
  serviceCycle: undefined,
  serviceProcess: undefined,
  serviceContext: undefined,
  releaseUnit: undefined,
  publisher: undefined,
  img: undefined,
  type: undefined
}*/

export default {
  name: 'addServe',
  components: {
    Uploader, Tinymce
  },
  data() {
    return {
      visibleA: false,
      drawer: false,
      titel: '发布',
      viceType: [],
      chargeList: [{
        lable: '货币',
        value: 0
        },
        {
        lable: '面议',
        value: 1
        }
        ],
      form: {
        id: undefined,
        serviceName: undefined,
        dictId: undefined,
        serviceLinkman: undefined,
        phone: undefined,
        serviceIntroduction: undefined,
        chargeType: undefined,
        serviceCycle: undefined,
        serviceProcess: undefined,
        serviceContext: undefined,
        releaseUnit: undefined,
        publisher: undefined,
        img: undefined,
        type: undefined,
        charge:undefined
      },
      rules : {
        serviceName:[{ required: true, message: '请输入服务名称', trigger: 'blur' }],
        dictId:[{ required: true, message: '请选择服务类型', trigger: 'blur' }],
        serviceLinkman:[{ required: true, message: '请输入服务联系人', trigger: 'blur' }],
        phone:[{ required: true, message: '请输入联系方式', trigger: 'blur' }],
        serviceIntroduction:[{ required: true, message: '请输入服务简介', trigger: 'blur' }],
        chargeType:[{ required: true, message: '请选择收费方式', trigger: 'blur' }],
        serviceCycle:[{ required: true, message: '请输入服务周期', trigger: 'blur' }],
        serviceProcess:[{ required: true, message: '请输入服务流程', trigger: 'blur' }],
        serviceContext:[{ required: true, message: '请填写服务内容', trigger: 'blur' }],
        releaseUnit:[{ required: true, message: '请填写发布单位', trigger: 'blur' }],
        publisher:[{ required: true, message: '请填写发布人', trigger: 'blur' }],
        type:[{ required: true, message: '请选择是否发布', trigger: 'change' }],
        img:[{ required: true, message: '请上传图片', trigger: 'change' }],
        charge:[{ required: true, message: '请填写收费金额', trigger: 'blur' }],
      }
    }
  },
  methods: {
    asdasd(){
      this.$refs.form.validateField( 'serviceContext')
    },
    turnOff() {
      this.$refs.form.resetFields()
    },
    goDemand(){
      this.$router.go(-1);
    },
   async  handleOk(e) {
     await  addVice(this.form).then(res=>{
        //console.log(res,e);
       this.$message.success("发布成功")
       this.$router.go(-1);
      })
      this.visibleA = false;
      this.turnOff();
    },
    onSubmit() {
      this.$refs.form.validate( async valid =>{
        if (valid) {
          this.visibleA = true;
        }
      })
    },
    setFileList(obj) {
      this.form[obj.field] = obj.img_str;
      this.$refs.form.validateField( 'img')
    },
    /*show() {
      this.drawer = true
      this.titel = '发布'
      this.form = Object.assign({}, Form)
      this.$nextTick(function() {
        this.$refs.form.clearValidate()
      })
    },
    showUpd(id){
      detailsVice({"id":id}).then(res=>{
        //console.log(res)
        this.form = res.data
        if(res.data.type===1){
          this.form.type='1'
        }else {
          this.form.type='0'
        }
        this.drawer = true
        this.titel = '修改'
      })
    },
    look(id) {
      detailsVice({ 'id': id }).then(res => {
        //console.log(res)
        this.form = res.data
        if (res.data.type === 1) {
          this.form.type = '1'
        } else {
          this.form.type = '0'
        }
        this.drawer = true
        this.titel = '详情'
      })
    },

   /!* getViceType() {
      viceTypeList().then(res => {
        this.viceType = res.data
      })
    },*!/
    onSubmit() {
      this.$refs.form.validate( async valid =>{
        if (valid) {
          addVice(this.form).then(res=>{
            this.$message.success("新增成功！")
            this.drawer = false
            this.$emit('refresh')
          })
        }
      })
    },
    onSubmitUpd(){
      this.$refs.form.validate(async valid => {
        if (valid) {
          updateVice(this.form).then(res=>{
            this.$message.success("修改成功！")
            this.drawer = false
            this.$emit('refresh')
          })
        }
      })
    },*/
  },
  mounted() {
    getDictType(7).then(res=>{
      this.viceType=res.data;
    });
  }
}

</script>

<style lang="scss" scoped>
.addServe{
  width: 1100px;
  height: 1570px;
  background: #FFFFFF;
  border: 2px solid #EFEFEF;
  margin-left: 110px;
  .demand_content {
    margin-top: 20px;
    // padding: 20px;
    /*  overflow-y: scroll;
      position: relative;*/
    .form-input{
      width: 435px;
    }
    .form-input :hover{
      border-color:#ff9a02;
    }
  }
  .add-heda{
    width: 1098px;
    height: 50px;
    background: #F9F9F9;
    border-bottom: 2px solid #EFEFEF;
    border-right: 2px solid #EFEFEF;
    .add-heda-text{
      width: 87px;
      height: 21px;
      font-size: 21px;
      font-family: Hiragino Sans GB !important;
      font-weight: normal;
      color: #000000;
      line-height: 45px;
      margin-left: 27px;
      font-weight: 600;
    }
  }
}



</style>
<style lang="scss" >
.ant-checkbox-wrapper:first-child{
  padding-left: 10px;
}
.addServe{
  .ant-input {
    width: 545px;
    height: 46px;
  }
  .ant-select-selection--single {
    height: 44px;
    width: 545px;
    margin-right: 10px;
    padding-top: 6px;
    border: 1px solid #E1E1E1;
  }
  .chargeTypeSelect{
    .ant-select-selection--single {
      width: 145px;
    }
  }
  .ant-select:focus {
    .ant-select-selection--single {
      border-color:#ff9a02;
      box-shadow:0px 0px 0px #ff9a02;
    }

  }
  .ant-select:hover{
    .ant-select-selection--single {
      border-color:#ff9a02;
    }
  }
  .ant-form-item-required{
    color: #666666 !important;
  }
}
</style>
