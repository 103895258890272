<template>
  <div class="clearfix">
    <a-upload
        ref="uploader"
        name="pic"
        :action="action"
        list-type="picture-card"
        :file-list="fileList"
        :before-upload="beforeUpload"
        :remove="handleRemove"
        @preview="handlePreview"
        @change="handleChange">
      <div class="upload-box" v-if="fileList.length < limit">
        <a-icon type="plus-circle"/>
        <div class="ant-upload-text">{{ desc }}</div>
      </div>
    </a-upload>
    <a-modal class="uploader" :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewImage"/>
    </a-modal>
  </div>
</template>
<script>
let {upload_url} = require('../../settings')

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export default {
  props: {
    field: {
      type: String,
      default: ''
    },
    imgStr: {
      type: String,
      default: ''
    },
    limit: {
      type: Number,
      default: 3
    },
    desc: {
      type: String,
      default: '上传图片'
    }
  },
  data() {
    return {
      action: upload_url,
      previewVisible: false,
      previewImage: '',
      fileList: [],
    };
  },
  methods: {
    beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        this.$message.error('只能上传jpg,png格式图片!');
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error('图片必须小于2M');
      }
      return isJpgOrPng && isLt2M;
    },
    handleCancel() {
      this.previewVisible = false;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleChange({file, fileList}) {
      if (file.status === 'done') {
        let img_str = this.toStr(fileList)
        this.$emit('setFileList', {field: this.field, img_str: img_str})
      }
      this.fileList = [...fileList]
    },
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
      let img_str = this.toStr(this.fileList)
      this.$emit('setFileList', {field: this.field, img_str: img_str})
    },
    toStr(fileList) {
      let imgAry = fileList.map(item => {
        if (item.response) {
          return item.response.data.url
        }
        return item.url
      })
      return imgAry.join(',')
    }
  },
  mounted() {
    if (!this.imgStr) return
    let ary = this.imgStr.split(',')
    this.fileList = []
    ary.forEach((item, index) => {
      this.fileList.push({
        uid: index,
        name: 'image.png',
        status: 'done',
        url: item,
      },)
    })
  },
  watch: {
    imgStr: function (newVal) {
      console.info(88441)
      if (newVal) {
        let ary = newVal.split(',')
        this.fileList = []
        ary.forEach((item, index) => {
          this.fileList.push({
            uid: index,
            name: 'image.png',
            status: 'done',
            url: item,
          },)
        })
      } else {
        this.fileList = []
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.upload-box {
  width: 140px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* you can make up upload button and sample style by using stylesheets */
.ant-upload-select-picture-card i {
  font-size: 31px;
  color: #cacaca;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  letter-spacing: 1px;
  color: #999999;
  font-size: 12px;
}

.uploader ::v-deep .ant-modal-body {
  padding: 0 !important;
}


</style>
